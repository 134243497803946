import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'primereact/card';

//act as proxy with gcloud function to get token so we can keep secrets hidden
class NotFound extends Component {
	render() {
		return (
			<Card title="Page not found">
				Page not found...<br />
				<Link to="/">Go Home</Link>
			</Card>
		);
	}
}
 
export default NotFound;