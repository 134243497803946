import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Redirect, Switch, BrowserRouter as Router } from 'react-router-dom';

import Resume from './Resume';
import Error from './Error';
import Notfound from './NotFound';

import './index.css';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import "primeflex/primeflex.css"
//import * as serviceWorker from './serviceWorker';

//TODO: login check
const session = sessionStorage.getItem('session')

const routing = (
	<Router>
		<Switch>
			<Route exact path = '/' component={Resume} />
			<Route path = '/not_found' component={Notfound} />
			<Route path = '/error' component={Error} />
			<Redirect to = '/not_found' />
		</Switch>
	</Router>
);
ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
