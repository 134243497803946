import React, { Component } from 'react';
import { Card } from 'primereact/card';

//act as proxy with gcloud function to get token so we can keep secrets hidden
class Resume extends Component {
	render() {
		return (
			<Card title="Under Development">
				This site is under development<br />
				<br />
				Download <a href="/resume.pdf" download>resume</a><br />
				<br />
				or visit my strava site <a href='http://www.kickassninja.com'>http://www.kickassninja.com</a>
			</Card>
		);
	}
}
 
export default Resume;